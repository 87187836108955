import { useMemo } from 'react';

import {
    isGermanUser,
    checkForScopes,
    allowPvInstallForGermanUsers,
} from '@tools/utils';

import { Scope } from '@tools/enums';
import { TelemetryTypes } from '@store/enums';
import { EnergyFlowScenarioState } from '@store/types';
import {
    useSales,
    useHasGcp,
    useHasSales,
    useHasBattery,
    useTenantData,
    useHasInverter,
    useAppSelector,
    useHasWallbox,
    useHasSmartMeter,
    useHasSmartDevices,
    useHasUkSmartMeter,
    // useHasElectricCar,
    useTelemetries,
    useHasHeatPump,
} from '@store/selectors';

export const useShouldAllowSelfOnboarding = () => {
    const sales = useSales();
    const hasSales = useHasSales();

    return useMemo(
        () =>
            isGermanUser()
                ? sales?.length
                    ? allowPvInstallForGermanUsers(sales[0].steps) // Assuming we have only one active sale
                    : false
                : !hasSales,
        [sales, hasSales],
    );
};

export const useEnergyFlowScenarios = (
    isLive: boolean,
): EnergyFlowScenarioState => {
    const hasGCP = useHasGcp();
    const hasBattery = useHasBattery();
    const hasWallbox = useHasWallbox();
    // const hasElectricCar = useHasElectricCar();
    const hasLiveData = useTelemetries(TelemetryTypes.PV_BATTERY).hasLiveData;
    const hasInverter = useHasInverter();
    const hasSmartMeter = useHasSmartMeter();
    const hasSmartDevices = useHasSmartDevices();
    const hasUkSmartMeter = useHasUkSmartMeter();
    const pvSelfOnboarding = useTenantData('pvSelfOnboarding');
    const hasReadingStartDate = useAppSelector(
        (state) => state.energy.hasReadingStartDate,
    );
    const shouldAllowSelfOnboarding = useShouldAllowSelfOnboarding();
    const hasSmartDevicesWithoutEmobility = useHasSmartDevices(false);
    const hasHeatPump = useHasHeatPump();

    const isPvWithSmartMeter = useMemo(
        () => hasSmartMeter && hasInverter,
        [hasInverter, hasSmartMeter],
    );

    const smartMeterOnly = useMemo(
        () => !isPvWithSmartMeter && !hasInverter && hasGCP,
        [hasGCP, hasInverter, isPvWithSmartMeter],
    );

    const isPvWithSmarthome = useMemo(
        () => hasInverter && !hasGCP && (hasSmartDevices || hasHeatPump),
        [hasGCP, hasInverter, hasSmartDevices, hasHeatPump],
    );

    const isSmartMeterOnly = useMemo(
        () => smartMeterOnly && !hasSmartDevices,
        [hasSmartDevices, smartMeterOnly],
    );

    const isSmartMeterAndSmartHome = useMemo(
        () => smartMeterOnly && hasSmartDevices,
        [hasSmartDevices, smartMeterOnly],
    );

    const isUkSmartMeterNoData = useMemo(
        () => hasUkSmartMeter && !hasReadingStartDate,
        [hasReadingStartDate, hasUkSmartMeter],
    );

    const isUkSmartMeter = useMemo(
        () =>
            hasUkSmartMeter &&
            hasReadingStartDate &&
            !hasInverter &&
            !hasBattery &&
            !hasSmartDevices,
        [
            hasUkSmartMeter,
            hasSmartDevices,
            hasReadingStartDate,
            hasBattery,
            hasInverter,
        ],
    );

    const hasNoDevices = useMemo(
        () =>
            !hasInverter &&
            !hasGCP &&
            !hasSmartDevices &&
            !hasWallbox &&
            // TODO EONFEH-13649: use electric car here once implementing energy flow for electric car
            //!hasElectricCar &&
            !hasUkSmartMeter,
        [
            hasGCP,
            hasInverter,
            hasSmartDevices,
            hasUkSmartMeter,
            hasWallbox,
            // TODO EONFEH-13649: use electric car here once implementing energy flow for electric car
            // hasElectricCar,
        ],
    );

    // Disabled in https://jira.eon.com/browse/HS-11800
    // const shouldShowSmartMeterUI = useMemo(
    //     () =>
    //         isGBUser() &&
    //         source === UserModel.SourceEnum.OpenRegistration &&
    //         !hasUkSmartMeter,
    //     [hasUkSmartMeter, source],
    // );

    const shouldShowSmartMeterUI = false;

    const isPVonly = useMemo(
        () => !hasBattery && !hasGCP && !hasUkSmartMeter && !hasSmartDevices,
        [hasGCP, hasSmartDevices, hasUkSmartMeter, hasBattery],
    );

    const isSmartHomeOnly = useMemo(
        () =>
            !hasInverter &&
            !hasGCP &&
            !hasBattery &&
            !hasUkSmartMeter &&
            hasSmartDevices,
        [hasGCP, hasSmartDevices, hasUkSmartMeter, hasBattery, hasInverter],
    );

    const hasNoLiveData = useMemo(
        () => hasInverter && !hasLiveData && isLive,
        [hasInverter, hasLiveData, isLive],
    );

    const shouldShowSelfOnboardingButton = useMemo(
        () =>
            !hasInverter &&
            !!pvSelfOnboarding &&
            !shouldShowSmartMeterUI &&
            shouldAllowSelfOnboarding &&
            checkForScopes([Scope.ENERGYDEVICES_PVB_WRITE]),
        [
            hasInverter,
            pvSelfOnboarding,
            shouldShowSmartMeterUI,
            shouldAllowSelfOnboarding,
        ],
    );

    return {
        isPVonly,
        hasWallbox,
        // TODO EONFEH-13649: use electric car here once implementing energy flow for electric car
        // hasElectricCar,
        hasInverter,
        hasNoDevices,
        hasSmartMeter,
        hasNoLiveData,
        smartMeterOnly,
        isUkSmartMeter,
        hasUkSmartMeter,
        hasSmartDevices,
        isSmartHomeOnly,
        isSmartMeterOnly,
        isPvWithSmarthome,
        isPvWithSmartMeter,
        isUkSmartMeterNoData,
        shouldShowSmartMeterUI,
        isSmartMeterAndSmartHome,
        shouldShowSelfOnboardingButton,
        hasSmartDevicesWithoutEmobility,
        hasHeatPump,
    };
};
