import { DropdownItem } from '@eon-home/react-library';

import { SignUpRequestModelCountryEnum } from '@swagger-http';

import { Language } from '../enums';
import { IS_NOT_DEV } from './env';

export const COUNTRY_OPTIONS: DropdownItem[] = [
    {
        code: 'en', // locale
        text: 'United Kingdom',
        value: 'GB', // region
        iso: SignUpRequestModelCountryEnum.EnGb,
    },
    {
        code: 'de', // locale
        text: 'Deutschland',
        value: 'DE', // region
        iso: SignUpRequestModelCountryEnum.DeDe,
    },
    {
        code: 'it', // locale
        text: 'Italia',
        value: 'IT', // region
        iso: SignUpRequestModelCountryEnum.ItIt,
    },
    {
        code: 'sv', // locale
        text: 'Sverige',
        value: 'SE', // region
        iso: SignUpRequestModelCountryEnum.SvSe,
    },
    {
        code: 'hu', // locale
        text: 'Magyarország',
        value: 'HU', // region
        iso: SignUpRequestModelCountryEnum.HuHu,
    },
    // TODO: Remove condition when PL is ready for production
    ...(IS_NOT_DEV
        ? []
        : [
              {
                  code: 'pl', // locale
                  text: 'Polska',
                  value: 'PL', // region
                  iso: 'pl_PL', // TODO: not present in the enum
              },
              {
                  code: 'nl', // locale
                  text: 'Nederland',
                  value: 'NL', // region
                  iso: 'nl_NL', // TODO: not present in the enum
              },
          ]),
];

export const LANGUAGE_OPTIONS: DropdownItem[] = [
    {
        code: 'en',
        text: `🇬🇧  ${Language.ENGLISH}`,
        value: 'en',
        iso: SignUpRequestModelCountryEnum.EnGb,
    },
    {
        code: 'de',
        text: `🇩🇪  ${Language.GERMAN}`,
        value: 'de',
        iso: SignUpRequestModelCountryEnum.DeDe,
    },
    {
        code: 'it',
        text: `🇮🇹  ${Language.ITALIAN}`,
        value: 'it',
        iso: SignUpRequestModelCountryEnum.ItIt,
    },
    {
        code: 'sv',
        text: `🇸🇪  ${Language.SWEDISH}`,
        value: 'sv',
        iso: SignUpRequestModelCountryEnum.SvSe,
    },
    {
        code: 'hu',
        text: `🇭🇺  ${Language.HUNGARIAN}`,
        value: 'hu',
        iso: SignUpRequestModelCountryEnum.HuHu,
    },
    {
        code: 'pl',
        text: `🇵🇱  ${Language.POLISH}`,
        value: 'pl',
        iso: 'pl_PL', // TODO: not present in the enum
    },
    {
        code: 'nl',
        text: `🇳🇱  ${Language.DUTCH}`,
        value: 'nl',
        iso: 'nl_NL', // TODO: not present in the enum
    },
];

// Time (in milliseconds) to wait before marking the user as idle
export const TIME_TO_IDLE = 1000 * 60 * 5; // The 5 minutes timeout was proposed by the P.O.

// Time (in milliseconds) to use in the Energy flow countdown when live data is still not available
export const LIVE_DATA_COUNTDOWN = 900000; // 15 minutes

// Time (in milliseconds) to use as an interval to fetch the current weather data
export const WEATHER_FETCH_INTERVAL = 900000; // 15 minutes

// Time (in milliseconds) to use as a timeout before opening an active accordion item
export const DEFAULT_ACCORDION_OPEN_TIME = 300;

// Time (in milliseconds) to use as an interval when polling the GCP devices endpoint
export const UK_SMART_METER_FETCH_INTERVAL = 1000 * 30; // 30 seconds

// Time (in milliseconds) to use as a timeout when Emobility's state is Finishing
// Allows us to hide any intermediate states sent after the finishing state
export const EMOBILITY_FINISHING_TIME = 6000;

// Time (in milliseconds) to use as a timeout before reverting any state property
export const CLEANUP_TIME = 3000;

// The uuids of the sale templates associated with the GridX customers. It's used to identify a GridX customer
export const GRIDX_SALE_UUIDS = [
    '6cdb7930-c199-11ed-afa1-0242ac120002',
    '2d9275f8-e6c4-4669-a36d-28a85af47a53',
];

export const DataAnalysisConfig = {
    NEXT_TRIGGER_DELAY: 4,
};
