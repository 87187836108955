import { FC, useRef, useMemo, useEffect } from 'react';
import usePortal from 'react-useportal';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Icon } from '@eon-home/react-library';

import { useToast } from '@store/selectors';
import { hideToast } from '@store/actions';
import { NumberOrNull } from '@tools/types';
import { ToastMessages, ToastType } from '@store/enums';
import { clearTimer, composeClassName, createTimer } from '@tools/utils';

import ErrorIconSrc from '@assets/icons/misc/false.svg';
import SuccessIconSrc from '@assets/icons/misc/tick.svg';

import './index.scss';

export const Toast: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toastState = useToast();
    const { type, text, visible } = toastState;

    const timer = useRef<NumberOrNull>(null);

    const { Portal } = usePortal({
        bindTo: document?.getElementById('root') ?? undefined,
    });

    const isSuccessToast = useMemo(() => type === ToastType.SUCCESS, [type]);

    const toastIcon = useMemo(
        () => (isSuccessToast ? SuccessIconSrc : ErrorIconSrc),
        [isSuccessToast],
    );

    const translatedText = useMemo(() => {
        switch (text) {
            case ToastMessages.SUCCESS:
                return t('Your settings have been saved.');
            case ToastMessages.ERROR:
                return t('Something went wrong. Please try again!');
            default:
                return '';
        }
    }, [t, text]);

    useEffect(() => {
        if (timer.current) {
            clearTimer(timer.current);
        }

        if (visible) {
            timer.current = createTimer('timeout', 3000, () => {
                dispatch(hideToast());
            });
        }

        return () => {
            clearTimer(timer.current);
        };
    }, [dispatch, visible]);

    return (
        <Portal>
            <div
                className={composeClassName('c-toast', [
                    visible && translatedText ? 'visible' : '',
                ])}
            >
                <div
                    className={composeClassName('c-toast__icon', [
                        isSuccessToast ? 'success' : 'error',
                    ])}
                >
                    <Icon src={toastIcon} />
                </div>

                <div className={'c-toast__text'}>
                    <p>{translatedText}</p>
                </div>
            </div>
        </Portal>
    );
};

export default Toast;
