import { FC, PointerEvent, useMemo, useEffect } from 'react';
import usePortal from 'react-useportal';

import { Button, UnknownFunction } from '@eon-home/react-library';

import { ReactChildren } from '@tools/types';
import { composeClassName } from '@tools/utils';
import { CloseButton, WithScrollBars } from '@components';

import './index.scss';

export interface PromptProps {
    hint?: string;
    title?: string;
    small?: boolean;
    medium?: boolean;
    alignLeft?: boolean;
    children?: ReactChildren;
    className?: string;
    cancelText?: string;
    confirmText?: string;
    onClose?: UnknownFunction;
    onCancel?: UnknownFunction;
    onConfirm?: UnknownFunction;
    isOverlaying?: boolean;
}

export const Prompt: FC<Readonly<PromptProps>> = ({
    title,
    hint,
    small,
    medium,
    children,
    alignLeft,
    className,
    cancelText,
    confirmText,
    onClose,
    onCancel,
    onConfirm,
    isOverlaying = false,
}: PromptProps) => {
    const { Portal } = usePortal({
        bindTo: (document && document.getElementById('root')) || undefined,
    });

    const hasActions = useMemo(
        () => (cancelText && onCancel) || (confirmText && onConfirm) || hint,
        [cancelText, confirmText, hint, onCancel, onConfirm],
    );

    const modalType = useMemo(() => {
        if (small) {
            return 'small';
        }

        if (medium) {
            return 'medium';
        }

        return '';
    }, [medium, small]);

    useEffect(() => {
        const handleEscapeKeyUp = (e: KeyboardEvent) => {
            e.key === 'Escape' && onClose && onClose();
        };

        document.body.classList.add('with--modal');

        if (onClose) {
            document.addEventListener('keyup', handleEscapeKeyUp);
        }

        return () => {
            if (isOverlaying) {
                return;
            }

            document.body.classList.remove('with--modal');

            if (onClose) {
                document.removeEventListener('keyup', handleEscapeKeyUp);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Portal>
            <div
                onClick={(e: PointerEvent<HTMLDivElement>): void => {
                    const target = e.target as HTMLDivElement;

                    if (
                        onClose &&
                        !target.closest('.c-prompt__body') &&
                        !target.classList.contains('c-prompt__body')
                    ) {
                        onClose();
                    }
                }}
                className={composeClassName(
                    'c-prompt',
                    [modalType, isOverlaying ? 'overlaying' : ''],
                    [className],
                )}
            >
                <WithScrollBars className="c-prompt__container">
                    <div className="c-prompt__body">
                        {onClose && <CloseButton onClose={onClose} />}

                        {title && (
                            <div className="c-prompt__header">
                                <h3 className="c-prompt__title">{title}</h3>
                            </div>
                        )}

                        <div
                            className={composeClassName('c-prompt__text', [
                                alignLeft ? 'alignleft' : '',
                            ])}
                        >
                            {children}
                        </div>

                        {hasActions && (
                            <div className="c-prompt__actions">
                                {cancelText && onCancel && (
                                    <Button
                                        type="button"
                                        outline
                                        onClick={onCancel}
                                        data-testid="prompt-cancel-button"
                                    >
                                        {cancelText}
                                    </Button>
                                )}

                                {confirmText && onConfirm && (
                                    <Button
                                        type="button"
                                        onClick={onConfirm}
                                        data-testid="prompt-confirm-button"
                                    >
                                        {confirmText}
                                    </Button>
                                )}

                                {hint && <p>{hint}</p>}
                            </div>
                        )}
                    </div>
                </WithScrollBars>
            </div>
        </Portal>
    );
};

export default Prompt;
