import { ElementType, FC } from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars-2';

import { ReactChildren } from '@tools/types';

interface Props {
    as?: ElementType;
    children: ReactChildren;
    className?: string;
}

const scrollbarSettings: ScrollbarProps = {
    autoHide: false,
    universal: false,
    autoHeight: false,
    thumbMinSize: 32,
    autoHeightMin: 0,
    autoHeightMax: 200,
    autoHideTimeout: 1000,
    autoHideDuration: 200,
    hideTracksWhenNotNeeded: false,
};

export const WithScrollBars: FC<Readonly<Props>> = ({
    as: As = 'div',
    children,
    className,
}: Props) =>
    className ? (
        <As className={className}>
            <Scrollbars {...scrollbarSettings}>{children}</Scrollbars>
        </As>
    ) : (
        <Scrollbars {...scrollbarSettings}>{children}</Scrollbars>
    );

export default WithScrollBars;
