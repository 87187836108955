export enum EnergyFlowResolution {
    LIVE = 'live',
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    THIRTY_DAYS = '30d',
}

export const enum EnergyFlowDataType {
    LIVE = 'LIVE',
    HISTORICAL = 'HISTORICAL',
}

export const enum EnergyFlowDotAnimationType {
    FORWARD = 'dots-animation-forward',
    BACKWARD = 'dots-animation-backward',
    NONE = '',
}

export enum LineTypes {
    PV1 = 'PV1',
    PV2 = 'PV2',
    PV3 = 'PV3',
    PV4 = 'PV4',
    PV5 = 'PV5',
    PV6 = 'PV6',
    BAT1 = 'BAT1',
    BAT2 = 'BAT2',
    BAT3 = 'BAT3',
    SL = 'SL',
    SP = 'SP',
    EV = 'EV',
    GRID1 = 'GRID1',
    GRID2 = 'GRID2',
    GRID3 = 'GRID3',
    HC = 'HC',
    SC = 'SC',
    HP1 = 'HP1',
    HP2 = 'HP2',
    STRAIGHT = 'STRAIGHT',
    STRAIGHT_ANIMATED = 'STRAIGHT_ANIMATED',
    MOBILE_STRAIGHT = 'MOBILE_STRAIGHT',
    MOBILE_TOP_LEFT = 'MOBILE_TOP_LEFT',
    MOBILE_TOP_RIGHT = 'MOBILE_TOP_RIGHT',
    MOBILE_BOTTOM_LEFT = 'MOBILE_BOTTOM_LEFT',
    MOBILE_BOTTOM_RIGHT = 'MOBILE_BOTTOM_RIGHT',
}

export const enum EnergyFlowActionTypes {
    GCP_STATUS = 'GCP_STATUS_HISTORICAL',
    EMOBILITY_ERROR = 'EMOBILITY_ERROR_HISTORICAL',
    EMOBILITY_STATUS = 'EMOBILITY_STATUS_HISTORICAL',
    PVBATTERY_STATUS = 'PVBATTERY_STATUS_HISTORICAL',
    SET_REQUEST_DATES = 'SET_REQUEST_DATES',
    SET_ERROR_PVB_GRID = 'SET_ERROR_PVB_GRID_HISTORICAL',
    EMOBILITY_INSTALLED = 'EMOBILITY_INSTALLED_HISTORICAL',
    SET_SMART_METER_STATE = 'SET_SMART_METER_STATE',
}

export const enum HouseHoldIcon {
    GCP = 'GCP',
    EMPTY = 'EMPTY',
    DEFAULT = 'DEFAULT ',
}
